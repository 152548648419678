<template>
    <div class="w-full dF fC f1 pb-4 hide-scrollbar" style="overflow-y:scroll">
        <SettingsModal />
        <a-modal :visible="premiumModal.visible" :centered="true" :footer="null" @cancel="onClose">
            <h5>{{premiumModal.type == 'add' ? 'New Lot Condition / Premium':'Edit Lot Condition / Premium'}}</h5>
            <a-form-model style="margin-top:24px" ref="newPremium" :model="newPremium">
                <a-row :gutter="16">
                    <a-col :span="12">
                        <a-form-model-item label="Name" required prop="name" :rules="req('Please fill in the Name')">
                            <a-input placeholder="Name" v-model="newPremium.name"></a-input>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-model-item style="width:100%" label="Default Price" prop="price">
                            <a-input-number style="width:100%" prefix="$" :formatter="value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" :parser="value => value.replace(/\$\s?|(,*)/g, '')" :min="0" placeholder="Default Price" v-model="newPremium.price"></a-input-number>
                        </a-form-model-item>
                    </a-col>
                </a-row>
            </a-form-model>
            <div style="width:100%; margin-top:24px; text-align:right">
                <a-button @click="onClose" class="mr-3 cancel-button">CANCEL</a-button>
                <a-button @click="submit(premiumModal.type)" type="primary">{{premiumModal.type == 'add' ? 'ADD PREMIUM':'UPDATE PREMIUM'}}</a-button>
            </div>
        </a-modal>
        <a-card title="Lot Conditions / Premiums">
            <a-table :rowKey="e => e.id" class="white-table" :columns="columns" :data-source="premiums">
                <div slot="user" slot-scope="obj">{{obj.user && obj.user.firstName && obj.user.lastName && obj.user.firstName!='' && obj.user.lastName!=''? `${obj.user.firstName} ${obj.user.lastName}`:''}}</div>
                <div slot="action" slot-scope="obj" class="dF">
                    <div @click="editPremium(obj)" class="mr-3" style="cursor:pointer"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg></div>
                    <div v-if="!defaultId.includes(obj.id)" @click="deletePremium(obj)" style="color:#FD647C; cursor:pointer"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg></div>
                </div>
                <div slot="price" slot-scope="obj">{{obj.price != null ? `$ ${obj.price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''}}</div>
                <div slot="date" slot-scope="obj" style="color:#9EA0A5">{{obj.date ? convertDate(obj.date) : ''}}</div>
            </a-table>
            <div @click="addPremium" style="padding-top:24px; cursor:pointer; color:var(--orange); display:inline-block">
                <div class="dF aC">
                    <a-icon style="font-size:20px" class="mr-3" type="plus-circle" />
                    <div>Add Lot Condition / Premium</div>
                </div>
            </div>
        </a-card>
    </div>
</template>

<script>
import SettingsModal from '@/components/siteplan/SettingsModal'
export default {
    components:{SettingsModal},
    watch:{
        '$route':{
			immediate:true,
			handler(val){
				if (val.path.includes('premiums')) this.$store.commit('SET_CRUMBS',['Lot Conditions and Premiums'])
			}
		},
    },
    computed: {
		dateFormat(){
			return this.$store.state.siteplan.allSettings.user && this.$store.state.siteplan.allSettings.user.options && this.$store.state.siteplan.allSettings.user.options.regional && this.$store.state.siteplan.allSettings.user.options.regional.dateFormat ? this.$store.state.siteplan.allSettings.user.options.regional.dateFormat : 'MM/DD/YYYY'
		},
        gradings() {
            if (this.$store.state.siteplan.allSettings && this.$store.state.siteplan.allSettings.app && this.$store.state.siteplan.allSettings.app.options && this.$store.state.siteplan.allSettings.app.options.gradings) return this.$store.state.siteplan.allSettings.app.options.gradings
			return []
        },
        premiums() {
            let p = this.$store.getters.lotPremiums;
			return p.sort((a,b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0).sort((a,b) => (b.date || 0) - (a.date || 0))
        },
        defaultId() {
            return this.defaultPremiums.map(x => x.id)
        }
    },
    data() {
        return{
            defaultPremiums:[
                {
                    active: false,
                    id:'WOD',
                    name:'Walk-out Deck',
                    price:null
                },
                {
                    active: false,
                    id:'LOB',
                    name:'Look-out Basement',
                    price:null
                },
                {
                    active: false,
                    id:'WOB',
                    name:'Walk-out Basement',
                    price:null
                },
                {
                    active: false,
                    id:'SU',
                    name:'Side Upgrade',
                    price:null
                },
                {
                    active: false,
                    id:'RU',
                    name:'Rear Upgrade',
                    price:null
                },
                {
                    active: false,
                    id:'CU',
                    name:'Corner Upgrade',
                    price:null
                },
                {
                    active: false,
                    id:'GU',
                    name:'Gateway Upgrade',
                    price:null
                },
                {
                    active: false,
                    id:'NSU',
                    name:'No Sidewalk Upgrade',
                    price:null
                },
                {
                    active: false,
                    id:'ConU',
                    name:'Conservation Upgrade',
                    price:null
                },
                {
                    active: false,
                    id:'DAB',
                    name:'Deck At Back',
                    price:null
                },
                {
                    active: false,
                    id:'DU',
                    name:'Depth Upgrade',
                    price:null
                },
            ],
            columns:[
                {
                    dataIndex:"name",
                    key:"name",
                    title:'Name',
					sorter: (a, b) =>
                                a.name < b.name ? -1 : a.name > b.name ? 1 : 0,
                },
                {
                    key:"price",
                    title:'Default Price',
                    slots:{title:'Default Price'},
                    scopedSlots:{customRender:'price'},
					sorter: (a, b) =>
                                a.price - b.price,
                },
                {
                    title:'Date Created',
                    key:'date',
                    slots:{title:'Date Created'},
                    scopedSlots:{customRender:'date'},
                    sorter: (a, b) => (a.date || 0) - (b.date || 0),
                },
                {
                    title:'Modified By',
                    key:'user',
                    slots:{title:'Modified By'},
                    scopedSlots:{customRender:'user'},
					sorter: (a, b) => {
						const aa = a.user && (a.user.firstName + a.user.lastName) || ''
						const bb = b.user && (b.user.firstName + b.user.lastName) || ''
						return aa < bb ? -1 : aa > bb ? 1 : 0
					}
                },
                {
                    title:'Actions',
                    key:'action',
                    slots:{title:'Actions'},
                    scopedSlots:{customRender:'action'}
                }
            ],
            premiumModal:{
                visible:false,
                type:'add'
            },
            newPremium:{
                name:'',
                price:'',
                date:'',
                id:'',
                user:{},
                active:false,
            }
        }
    },
    methods:{
        convertDate(num){
			let x = new Date(num)
			//Tue Oct 06 2020 12:11:07 GMT-0400 (Eastern Daylight Time)
			let year = x.getFullYear()
			let month = x.getMonth()+1
			let day = x.getDate()
			if (this.dateFormat == 'YYYY/MM/DD') return year+'/'+month+'/'+day
			else if (this.dateFormat == 'DD/MM/YYYY') return day+'/'+month+'/'+year
			else {
				return month+'/'+day+'/'+year
			}
		},
        submit(type) {
            if (type == 'add') this.createPremium()
            else if (type == 'edit') this.updatePremium()
        },
        updatePremium() {
            this.$refs.newPremium.validate(valid => {
                if(valid) {
                    let obj = JSON.parse(JSON.stringify(this.newPremium))
                    obj.name = obj.name.trim()
                    obj.price = parseFloat(obj.price)
                    obj.date = Date.now()
                    obj.user = {
                        firstName:this.$store.state.user.user.firstName,
                        lastName:this.$store.state.user.user.lastName,
                        id:this.$store.state.user.user.id
                    }
                    obj.active = false
                    let premiums = []
                    if (Object.keys(obj).length != 0) {
                        this.premiums.forEach(premium => {
                            if (premium.id == obj.id) {
                                premiums.push(obj)
                            } else {
                                premiums.push(premium)
                            }
                        })
                    }
                    if (premiums.length != 0 && (!this.gradings.length || this.gradings.length == 0)) {
                        this.$api.post(`/settings/:instance/siteplan`, {options:{premiums:premiums}}).then(({data}) => {
                            this.$store.commit('UPDATE_SETTINGS', data)
                            this.onClose()
                        }).catch(err => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err))
							}
						})
                    } else if (premiums.length != 0 && this.gradings.length) {
                        this.$api.post(`/settings/:instance/siteplan`, {options:{premiums:premiums, gradings:this.gradings}}).then(({data}) => {
                            this.$store.commit('UPDATE_SETTINGS', data)
                            this.onClose()
                        }).catch(err => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err))
							}
						})
                    }
                }
            })
        },
        createPremium() {
            this.$refs.newPremium.validate(valid => {
                if(valid) {
                    let obj = {}
                    obj.name = this.newPremium.name.trim()
                    obj.price = parseFloat(this.newPremium.price)
                    obj.date = Date.now()
                    obj.id = Date.now().toString()
                    obj.user = {
                        firstName:this.$store.state.user.user.firstName,
                        lastName:this.$store.state.user.user.lastName,
                        id:this.$store.state.user.user.id
                    }
                    obj.active = false
                    console.log('OBJ FROM CREATE PREMIUM', obj)
                    if ((!this.premiums.length || this.premiums.length == 0) && (!this.gradings.length || this.gradings.length == 0)) {
                        this.$api.post(`/settings/:instance/siteplan`, {options:{premiums:[obj]}}).then(({data}) => {
                            this.$store.commit('UPDATE_SETTINGS', data)
                            this.onClose()
                        }).catch(err => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err))
							}
						})
                    } else if (this.premiums.length && (!this.gradings.length || this.gradings.length == 0)) {
                        let premiums = JSON.parse(JSON.stringify(this.premiums))
                        premiums.push(obj)
                        this.$api.post(`/settings/:instance/siteplan`, {options:{premiums:premiums}}).then(({data}) => {
                            this.$store.commit('UPDATE_SETTINGS', data)
                            this.onClose()
                        }).catch(err => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err))
							}
						})
                    } else if (this.premiums.length && this.gradings.length) {
                        let premiums = JSON.parse(JSON.stringify(this.premiums))
                        premiums.push(obj)
                        this.$api.post(`/settings/:instance/siteplan`, {options:{premiums:premiums, gradings:this.gradings}}).then(({data}) => {
                            this.$store.commit('UPDATE_SETTINGS', data)
                            this.onClose()
                        }).catch(err => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err))
							}
						})
                    } else if ((!this.premiums.length || this.premiums.length == 0) && this.gradings.length) {
                        this.$api.post(`/settings/:instance/siteplan`, {options:{premiums:[obj], gradings:this.gradings}}).then(({data}) => {
                            this.$store.commit('UPDATE_SETTINGS', data)
                            this.onClose()
                        }).catch(err => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err))
							}
						})
                    }
                }
            })
        },
        req:msg=>({required:true,message:msg}),
        editPremium(obj) {
            this.premiumModal.visible = true
            this.premiumModal.type = 'edit'
            this.newPremium = JSON.parse(JSON.stringify(obj))
            if (this.newPremium.price == null || this.newPremium.price == '') this.newPremium.price = 0
            else this.newPremium.price = parseInt(this.newPremium.price)
        },
        deletePremium(obj) {
            let self = this
            this.$confirm({
                title: "Delete Lot Premium",
                content: h => <div>Do you want to delete this Lot Premium?</div>,
                okText: 'Delete',
                okType: 'danger',
                cancelText: 'Cancel',
                centered: true,
                onOk() {
                    let premiums = JSON.parse(JSON.stringify(self.premiums))
                    let index = premiums.findIndex(x => x.id == obj.id)
                    premiums.splice(index, 1)
                    if (!self.gradings.length || self.gradings.length == 0) {
                        self.$api.post(`/settings/:instance/siteplan`, {options:{premiums:premiums}}).then(({data}) => {
                            self.$store.commit('UPDATE_SETTINGS', data)
                        }).catch(err => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								self.$message.error(self.$err(err))
							}
						})
                    } else {
                        self.$api.post(`/settings/:instance/siteplan`, {options:{premiums:premiums, gradings:self.gradings}}).then(({data}) => {
                            self.$store.commit('UPDATE_SETTINGS', data)
                        }).catch(err => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								self.$message.error(self.$err(err))
							}
						})
                    }
                },
                onCancel() {
                    console.log('Cancel')
                }
            })
        },
        addPremium() {
            // this.premiumModal.visible = true
            // this.premiumModal.type = 'add'
            this.$store.commit('OPEN_SETTINGS_MODAL', 'premium')
        },
        onClose() {
            this.premiumModal.visible = false
            this.$refs.newPremium.resetFields()
            this.newPremium = {
                name:'',
                price:'',
                date:'',
                id:'',
                user:{},
                active:false
            }
        }
    }
}
</script>

<style>
.cancel-button.ant-btn {
  border-color:#ECE9F1 !important;
  background-color:#ECE9F1;
  color:#3F3356
}

</style>
