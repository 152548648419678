<template>
	<div class="w-full dF fC f1 px-4 pb-4 hide-scrollbar" style="background-color: #F7F5F9; overflow: scroll;">
		<h4 class="mb-3">Settings</h4>
		<SettingsTab @menuClick="onTabChange" :menuList="menuList" :activeTab="activeTab" width="210">
			<Premiums v-if="activeTab === 'premiums'" />
			<Gradings v-else-if="activeTab === 'gradings'" />
			<Conditions v-else-if="activeTab === 'conditions'" />
			<ManageData v-else-if="activeTab === 'manageData'" />
			<CustomFields v-else-if="activeTab === 'customFields'" />
		</SettingsTab>
	</div>
</template>

<script>

import SettingsTab from 'bh-mod/components/common/SettingsTab'
import Premiums from './premiums'
import Gradings from './gradings.vue'
import Conditions from './conditions.vue'
import ManageData from './ManageData'
import CustomFields from './customFields'

export default {
	components: {
		SettingsTab, Premiums, Gradings, Conditions, ManageData, CustomFields
	},
	data() {
		return {
			activeTab: 'gradings',
			menuList: [
				{
					label: 'Customization', id: 'sub1',
					children: [{ label: 'Lot Gradings', id: 'gradings' }, { label: 'Lot Conditions/Premiums', id: 'premiums' }, { label: 'Custom Fields', id: 'customFields' }],
					info: 'Warning: If you delete any customizations, you will lose all the data associated with it inside your models'
				},
				{
					label: 'Data Management', id: 'sub2', children: [{ label: 'Manage Data', id: 'manageData' }]
				},
			],
		}
	},
	methods: {
		onTabChange(e) {
			this.activeTab = e
		},
	},
	created() {
		this.$store.commit("SET_CRUMBS", ['Settings']);
		this.$store.commit("changeType", 'settings');
	}
}
</script>

<style>

</style>

