<template>
    <div class="w-full dF fC f1 pb-4 hide-scrollbar" style="overflow-y:scroll">
        <SettingsModal />
        <a-modal :visible="gradingModal.visible" :centered="true" :footer="null" @cancel="onClose">
            <h5>{{gradingModal.type == 'add' ? 'New Custom Grading':'Edit Lot Grading'}}</h5>
            <a-form-model style="margin-top:24px" ref="newGrading" :model="newGrading">
                <a-row :gutter="16">
                    <a-col :span="23">
                        <a-form-model-item label="Name" required prop="name" :rules="req('Please fill in the Name')">
                            <a-input placeholder="Name" v-model="newGrading.name"></a-input>
                        </a-form-model-item>
                    </a-col>
                    <!-- <a-col :span="12">
                        <a-form-model-item label="Default Price" prop="price">
                            <a-input type="number" :min="0" placeholder="Default Price" v-model="newGrading.price"></a-input>
                        </a-form-model-item>
                    </a-col> -->
                </a-row>
            </a-form-model>
            <div style="width:100%; margin-top:24px; text-align:right">
                <a-button @click="onClose" class="mr-3 cancel-button">CANCEL</a-button>
                <a-button @click="submit(gradingModal.type)" type="primary">{{gradingModal.type == 'add' ? 'ADD GRADING':'UPDATE GRADING'}}</a-button>
            </div>
        </a-modal>
        <a-card title="Lot Gradings">
            <a-table :rowKey="e => e.id" class="white-table" :columns="columns" :data-source="gradings">
                <div slot="user" slot-scope="obj">{{obj.user && obj.user.firstName && obj.user.lastName && obj.user.firstName!='' && obj.user.lastName!=''? `${obj.user.firstName} ${obj.user.lastName}`:''}}</div>
                <div slot="action" slot-scope="obj" class="dF">
                    <div @click="editGrading(obj)" class="mr-3" style="cursor:pointer"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg></div>
                    <div @click="deleteGrading(obj)" style="color:#FD647C; cursor:pointer"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg></div>
                </div>
                <div slot="date" slot-scope="obj" style="color:#9EA0A5">{{obj.date ? convertDate(obj.date) : ''}}</div>
            </a-table>
            <div @click="addGrading" style="padding-top:24px; cursor:pointer; color:var(--orange); display:inline-block">
                <div class="dF aC">
                    <a-icon style="font-size:20px" class="mr-3" type="plus-circle" />
                    <div>Add Lot Grading</div>
                </div>
            </div>
        </a-card>
    </div>
</template>

<script>
import SettingsModal from '@/components/siteplan/SettingsModal'
export default {
    components:{SettingsModal},
    watch:{
        '$route':{
			immediate:true,
			handler(val){
				if (val.path.includes('gradings')) this.$store.commit('SET_CRUMBS',['Lot Gradings'])
			}
		},
    },
    computed: {
		dateFormat(){
			return this.$store.state.siteplan.allSettings.user && this.$store.state.siteplan.allSettings.user.options && this.$store.state.siteplan.allSettings.user.options.regional && this.$store.state.siteplan.allSettings.user.options.regional.dateFormat ? this.$store.state.siteplan.allSettings.user.options.regional.dateFormat : 'MM/DD/YYYY'
		},
        gradings() {
			let g = []
            if (this.$store.state.siteplan.allSettings && this.$store.state.siteplan.allSettings.app && this.$store.state.siteplan.allSettings.app.options && this.$store.state.siteplan.allSettings.app.options.gradings) g = this.$store.state.siteplan.allSettings.app.options.gradings
			return g.sort((a,b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0).sort((a,b) => (b.date || 0) - (a.date || 0))
        },
        premiums() {
            if (this.$store.state.siteplan.allSettings && this.$store.state.siteplan.allSettings.app && this.$store.state.siteplan.allSettings.app.options && this.$store.state.siteplan.allSettings.app.options.premiums) return this.$store.state.siteplan.allSettings.app.options.premiums
			return []
        }
    },
    data() {
        return{
            columns:[
                {
                    dataIndex:"name",
                    key:"name",
                    title:'Name',
					sorter: (a, b) =>
                                a.name < b.name ? -1 : a.name > b.name ? 1 : 0,
                },
                {
                    title:'Date Created',
                    key:'date',
                    slots:{title:'Date Created'},
                    scopedSlots:{customRender:'date'},
                    sorter: (a, b) => (a.date || 0) - (b.date || 0),
                },
                {
                    title:'Modified By',
                    key:'user',
                    slots:{title:'Modified By'},
                    scopedSlots:{customRender:'user'},
					sorter: (a, b) => {
						const aa = a.user && (a.user.firstName + a.user.lastName) || ''
						const bb = b.user && (b.user.firstName + b.user.lastName) || ''
						return aa < bb ? -1 : aa > bb ? 1 : 0
					}
                },
                {
                    title:'Actions',
                    key:'action',
                    slots:{title:'Actions'},
                    scopedSlots:{customRender:'action'}
                }
            ],
            gradingModal:{
                visible:false,
                type:'add'
            },
            newGrading:{
                name:'',
                price:'',
                date:'',
                id:'',
                user:{},
                active:false
            }
        }
    },
    methods:{
        convertDate(num){
			let x = new Date(num)
			//Tue Oct 06 2020 12:11:07 GMT-0400 (Eastern Daylight Time)
			let year = x.getFullYear()
			let month = x.getMonth()+1
			let day = x.getDate()
			if (this.dateFormat == 'YYYY/MM/DD') return year+'/'+month+'/'+day
			else if (this.dateFormat == 'DD/MM/YYYY') return day+'/'+month+'/'+year
			else {
				return month+'/'+day+'/'+year
			}
		},
        submit(type) {
            if (type == 'add') this.createGrading()
            else if (type == 'edit') this.updateGrading()
        },
        updateGrading() {
            this.$refs.newGrading.validate(valid => {
                if(valid) {
                    let obj = JSON.parse(JSON.stringify(this.newGrading))
                    obj.name = obj.name.trim()
                    obj.price = parseFloat(obj.price)
                    obj.date = Date.now()
                    obj.user = {
                        firstName:this.$store.state.user.user.firstName,
                        lastName:this.$store.state.user.user.lastName,
                        id:this.$store.state.user.user.id
                    }
                    obj.active = false
                    let gradings = []
                    if (Object.keys(obj).length != 0) {
                        this.gradings.forEach(grading => {
                            if (grading.id == obj.id) {
                                gradings.push(obj)
                            } else {
                                gradings.push(grading)
                            }
                        })
                    }
                    if (gradings.length != 0 && (!this.premiums.length || this.premiums.length == 0)) {
                        this.$api.post(`/settings/:instance/siteplan`, {options:{gradings:gradings}}).then(({data}) => {
                            this.$store.commit('UPDATE_SETTINGS', data)
                            this.onClose()
                        }).catch(err => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err))
							}
						})
                    } else if (gradings.length != 0 && this.premiums.length) {
                        this.$api.post(`/settings/:instance/siteplan`, {options:{premiums:this.premiums, gradings:gradings}}).then(({data}) => {
                            this.$store.commit('UPDATE_SETTINGS', data)
                            this.onClose()
                        }).catch(err => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err))
							}
						})
                    }
                }
            })
        },
        createGrading() {
            this.$refs.newGrading.validate(valid => {
                if(valid) {
                    let obj = {}
                    obj.name = this.newGrading.name.trim()
                    obj.price = parseFloat(this.newGrading.price)
                    obj.date = Date.now()
                    obj.id = Date.now().toString()
                    obj.user = {
                        firstName:this.$store.state.user.user.firstName,
                        lastName:this.$store.state.user.user.lastName,
                        id:this.$store.state.user.user.id
                    }
                    obj.active = false
                    console.log('OBJ FROM CREATE GRADING', obj)
                    if ((!this.gradings.length || this.gradings.length == 0) && (!this.premiums.length || this.premiums.length == 0)) {
                        this.$api.post(`/settings/:instance/siteplan`, {options:{gradings:[obj]}}).then(({data}) => {
                            this.$store.commit('UPDATE_SETTINGS', data)
                            this.onClose()
                        }).catch(err => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err))
							}
						})
                    } else if (this.gradings.length && (!this.premiums.length || this.premiums.length == 0)) {
                        let gradings = JSON.parse(JSON.stringify(this.gradings))
                        gradings.push(obj)
                        this.$api.post(`/settings/:instance/siteplan`, {options:{gradings:gradings}}).then(({data}) => {
                            this.$store.commit('UPDATE_SETTINGS', data)
                            this.onClose()
                        }).catch(err => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err))
							}
						})
                    }
                    else if (this.gradings.length && this.premiums.length) {
                        let gradings = JSON.parse(JSON.stringify(this.gradings))
                        gradings.push(obj)
                        this.$api.post(`/settings/:instance/siteplan`, {options:{premiums:this.premiums, gradings:gradings}}).then(({data}) => {
                            this.$store.commit('UPDATE_SETTINGS', data)
                            this.onClose()
                        }).catch(err => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err))
							}
						})
                    } else if ((!this.gradings.length || this.gradings.length == 0) && this.premiums.length) {
                        this.$api.post(`/settings/:instance/siteplan`, {options:{premiums:this.premiums, gradings:[obj]}}).then(({data}) => {
                            this.$store.commit('UPDATE_SETTINGS', data)
                            this.onClose()
                        }).catch(err => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err))
							}
						})
                    }
                }
            })
        },
        req:msg=>({required:true,message:msg}),
        editGrading(obj) {
            this.gradingModal.visible = true
            this.gradingModal.type = 'edit'
            this.newGrading = JSON.parse(JSON.stringify(obj))
        },
        deleteGrading(obj) {
            let self = this
            this.$confirm({
                title: "Delete Custom Grading",
                content: h => <div>Do you want to delete this Custom Grading?</div>,
                okText: 'Delete',
                okType: 'danger',
                cancelText: 'Cancel',
                centered: true,
                onOk() {
                    let gradings = JSON.parse(JSON.stringify(self.gradings))
                    let index = gradings.findIndex(x => x.id == obj.id)
                    gradings.splice(index, 1)
                    if (!self.premiums.length || self.premiums.length == 0) {
                        self.$api.post(`/settings/:instance/siteplan`, {options:{gradings:gradings}}).then(({data}) => {
                            self.$store.commit('UPDATE_SETTINGS', data)
                        }).catch(err => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								self.$message.error(self.$err(err))
							}
						})
                    } else {
                        self.$api.post(`/settings/:instance/siteplan`, {options:{premiums:self.premiums, gradings:gradings}}).then(({data}) => {
                            self.$store.commit('UPDATE_SETTINGS', data)
                        }).catch(err => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								self.$message.error(self.$err(err))
							}
						})
                    }
                },
                onCancel() {
                    console.log('Cancel')
                }
            })
        },
        addGrading() {
            // this.gradingModal.visible = true
            // this.gradingModal.type = 'add'
            this.$store.commit('OPEN_SETTINGS_MODAL', 'grading')
        },
        onClose() {
            this.gradingModal.visible = false
            this.$refs.newGrading.resetFields()
            this.newGrading = {
                name:'',
                price:'',
                date:'',
                id:'',
                user:{},
                active:false
            }
        }
    }
}
</script>

<style>
.cancel-button.ant-btn {
  border-color:#ECE9F1 !important;
  background-color:#ECE9F1;
  color:#3F3356
}

</style>
