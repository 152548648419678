<template>
	<div class="w-full dF fC f1 pb-4 hide-scrollbar" style="overflow-y:scroll">
		<BHLoading :show="loading" />
		<a-card title="Custom Fields">
			<a-table :pagination="false" :rowKey="(e) => e.id" class="white-table" :columns="columns"
				:data-source="customFields">
				<div slot="type" slot-scope="obj" class="dF aC">
					{{ obj.type === 'multiplechoice' ? 'Multiple Choice' : obj.type === 'text' ? 'Text' : obj.type ===
						'checkbox' ? 'Checkbox' : '' }}
				</div>
				<div slot="default" slot-scope="obj" class="dF aC">
					<template v-if="typeof obj.options.default == 'object'">
						<div :class="optionI != 0 ? 'ml-2' : ''" class="px-2 py-1"
							style="border-radius:4px; background-color:#ECE9F1"
							v-for="(option, optionI) in obj.options.default" :key="optionI">
							{{ obj.options.multipleChoices[option] }}</div>
					</template>
					<template v-else>
						<div v-if="obj.options.multipleChoices && obj.options.multipleChoices.length">
							{{ obj.options.multipleChoices[obj.options.default] }}</div>
						<div v-else>{{ obj.options.default }}</div>
					</template>
				</div>
				<div slot="type" slot-scope="obj">
					{{ obj.type.charAt(0).toUpperCase() + obj.type.slice(1) }}
				</div>
				<div slot="createdAt" slot-scope="obj" class="dF aC" style="color: #9EA0A5;">
					{{ convertDate(obj.createdAt) }}
				</div>
				<div slot="user" slot-scope="obj">{{ obj.user && obj.user.firstName && obj.user.lastName &&
					obj.user.firstName != '' && obj.user.lastName != '' ? `${obj.user.firstName} ${obj.user.lastName}` : '' }}</div>
				<div slot="action" slot-scope="obj" class="dF jE cursor-pointer">
					<svg @click="editField(obj)" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
						viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
						stroke-linejoin="round" class="feather feather-edit-2 mr-3 editIcon">
						<path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
					</svg>
					<i @click="deleteField(obj)" class="fe fe-trash-2 deleteIcon" style="font-size: 16px; " />
				</div>
			</a-table>
			<div @click="showFieldModal = true" class="dF aC"
				style="margin-top:24px; color:var(--orange); cursor:pointer; width: 150px;">
				<a-icon style="font-size:25px" type="plus-circle" class="mr-3" />
				<div>Add Custom Field</div>
			</div>
		</a-card>

		<a-modal :width="'600px'" centered :header="null" :footer="null" :visible="showFieldModal" @cancel="cancelField">
			<h5>{{ title }}</h5>
			<a-row :gutter="16" class="mt-5">
				<a-form-model ref="newField" :model="newField">
					<a-col :span="24">
						<a-form-model-item prop="name" label="Name" required :rules="req('Please enter the Name')">
							<a-input v-model="newField.name" size="large" placeholder="Enter the Name"></a-input>
						</a-form-model-item>
					</a-col>
					<a-col :span="24" class="mb-4">
						<a-checkbox v-model="newField.required">Required</a-checkbox>
					</a-col>
					<a-col :span="24">
						<a-form-model-item prop="type" label="Field Type" required
							:rules="req('Please choose a type for your field')">
							<a-select @change="typeSelected" v-model="newField.type" size="large">
								<a-select-option v-for="t in types" :key="t.value">{{ t.label }}</a-select-option>
							</a-select>
						</a-form-model-item>
					</a-col>
					<a-col :span="24" v-if="newField.type == 'multiplechoice'">
						<a-form-model-item>
							<a-checkbox v-model="multiple">Allow Multiple Answers</a-checkbox>
						</a-form-model-item>
					</a-col>
					<a-col :span="24" v-if="newField.type === 'multiplechoice' || newField.type === 'checkbox'">
						<a-form-model-item v-for="(option, optionI) in options" :key="optionI"
							:label="`Options #${optionI + 1}`">
							<div class="dF aC" style="justify-content:space-between">
								<a-input @change="changeOption(option, optionI)" v-model="option.text"
									class="flex-1"></a-input>

								<a-icon class="ml-2" v-if="optionI == options.length - 1"
									@click="addOptions(options[options.length - 1])"
									style="font-size:25px; color: var(--orange)" type="plus-circle" />
								<a-icon class="ml-2" v-if="options.length > 2 && optionI != options.length - 1"
									@click="options.splice(optionI, 1)" style="font-size:25px; color: var(--orange)"
									type="minus-circle" />
							</div>
						</a-form-model-item>
					</a-col>
					<a-col :span="24" v-if="newField.type == 'text'">
						<a-form-model-item prop="value" label="Default Value">
							<a-input v-model="newField.value" size="large" placeholder="Enter the Default Value"></a-input>
						</a-form-model-item>
					</a-col>
					<a-col :span="24" v-else-if="newField.type == 'number'">
						<a-form-model-item prop="value" label="Default Value">
							<a-input type="number" v-model="newField.value" size="large"
								placeholder="Enter the Default Value" :default-value="0"></a-input>
						</a-form-model-item>
					</a-col>
					<a-col :span="24" v-else-if="newField.type === 'multiplechoice' || newField.type === 'checkbox'">
						<a-form-model-item prop="value" label="Default Value">
							<a-select v-model="newField.value" size="large" placeholder="Select a Default Value"
								:mode="newField.type == 'checkbox' ? '' : newField.type == 'multiplechoice' && multiple ? 'multiple' : ''">
								<a-select-option v-for="(value, valueI) in filterOptions(options)" :key="valueI"
									:value="valueI">{{ value.text }}</a-select-option>
							</a-select>
						</a-form-model-item>
					</a-col>
					<a-col :span="24" v-else-if="newField.type === 'date'">
						<a-form-model-item prop="value" label="Default Value">
							<a-date-picker style="width:100%" v-model="newField.value" size="large"
								placeholder="Select a Default Value" />
						</a-form-model-item>
					</a-col>
				</a-form-model>
			</a-row>
			<a-row type="flex" class="mt-3">
				<a-col :span="12">
					<a-button @click="deleteField(newField)" size="large" type="danger" v-if="newField.id"><a-icon
							type="delete" />DELETE</a-button>
				</a-col>
				<a-col :span="12" style="text-align: right;">
					<a-button @click="cancelField" size="large" class="cancel-button">CANCEL</a-button>
					<a-button @click="submitField" type="primary" size="large" class="ml-3">{{ newField.id ?
						'UPDATE' : 'CREATE' }}</a-button>
				</a-col>
			</a-row>
		</a-modal>
	</div>
</template>

<script>
import BHLoading from 'bh-mod/components/common/Loading'
import moment from 'moment'

export default {
	components: { BHLoading },
	data() {
		return {
			loading: false,
			showFieldModal: false,
			title: 'Add Custom Field',
			columns: [
				{
					title: 'Name',
					dataIndex: 'name',
					key: 'name',
					sorter: (a, b) =>
                                a.name < b.name ? -1 : a.name > b.name ? 1 : 0,
				},
				{
					title: 'Field Type',
					key: 'type',
					scopedSlots: { customRender: 'type' },
					sorter: (a, b) =>
                                a.type < b.type ? -1 : a.type > b.type ? 1 : 0,
				},
				{
					title: 'Default Value',
					key: 'default',
					scopedSlots: { customRender: 'default' },
				},
				{
					title: 'Date Created',
					key: 'createdAt',
					scopedSlots: { customRender: 'createdAt' },
					sorter: (a, b) => moment(a.createdAt).format('x') - moment(b.createdAt).format('x'),
				},
				{
					title: 'Modified By',
					key: 'user',
					slots: { title: 'Modified By' },
					scopedSlots: { customRender: 'user' },
					sorter: (a, b) => {
						const aa = a.user && (a.user.firstName + a.user.lastName) || ''
						const bb = b.user && (b.user.firstName + b.user.lastName) || ''
						return aa < bb ? -1 : aa > bb ? 1 : 0
					}
				},
				{
					title: 'Action',
					key: 'action',
					align: 'right',
					scopedSlots: { customRender: 'action' },
				},
			],
			newField: {
				name: '',
				required: false,
				type: 'text',
				value: ''
			},
			types: [
				{ value: 'text', label: 'Text' },
				{ value: 'number', label: 'Number' },
				{ value: 'checkbox', label: 'Check Box' },
				{ value: 'multiplechoice', label: 'Multiple Choice' },
				{ value: 'date', label: 'Date (MM/DD/YYYY)' }
			],
			options: [{
				text: ''
			}],
			multiple: false,
			refresh: new Date().getTime()
		}
	},
	computed: {
		dateFormat() {
			return this.$store.state.siteplan.allSettings.user && this.$store.state.siteplan.allSettings.user.options && this.$store.state.siteplan.allSettings.user.options.regional && this.$store.state.siteplan.allSettings.user.options.regional.dateFormat ? this.$store.state.siteplan.allSettings.user.options.regional.dateFormat : 'MM/DD/YYYY'
		},
		customFields() {
			let refresh = this.refresh;
			return Object.values(this.$store.state.siteplan.customFields)
		},
		user() {
			return this.$store.state.user.user
		}
	},
	methods: {
		moment,
		req: msg => ({ required: true, message: msg }),

		filterOptions(arr) {
			return arr.filter(x => x.text != '')
		},

		changeOption(text, index) {
			if (text.text.length == 0) {
				this.options.splice(index, 1)
			}
		},

		addOptions(lastOption) {
			if (!lastOption.text) return this.$message.error('You need to fill in the field before adding a new one')
			else {
				let option = {
					text: ''
				}
				this.options.push(option)
			}
		},

		typeSelected(e) {
			if (e == 'text') {
				this.newField.value = ''
				this.options = [];
			} else if (e == 'number') {
				this.newField.value = 0
				this.options = [];
			} else if (e == 'checkbox' || e == 'multiplechoice') {
				this.newField.value = []
				this.options = [{ text: '' }]
			} else if (e == 'date') {
				this.newField.value = this.moment(Date.now())
				this.options = [];
			}
		},

		convertDate(num) {
			let x = new Date(num)
			//Tue Oct 06 2020 12:11:07 GMT-0400 (Eastern Daylight Time)
			let year = x.getFullYear()
			let month = x.getMonth() + 1
			let day = x.getDate()
			if (this.dateFormat == 'YYYY/MM/DD') return year + '/' + month + '/' + day
			else if (this.dateFormat == 'DD/MM/YYYY') return day + '/' + month + '/' + year
			else {
				return month + '/' + day + '/' + year
			}
		},

		editField(item) {
			this.title = 'Edit Custom Field'
			let newField = JSON.parse(JSON.stringify(item))
			newField.value = newField.type === 'number' ? Number(item.options.default) : newField.type === 'date' ? moment(item.options.default) : item.options.default
			newField.required = !!newField.options.required;
			this.options = newField.options.multipleChoices.map(x => x = { text: x })
			delete newField.options
			this.newField = JSON.parse(JSON.stringify(newField))
			this.showFieldModal = true
		},

		cancelField() {
			this.refresh = new Date().getTime();
			this.$refs['newField'].resetFields()
			this.newField = {
				name: '',
				required: false,
				type: 'text',
				value: '',
			};
			this.title = 'Add Custom Field'
			this.showFieldModal = false
			this.options = [{
				text: ''
			}]
		},
		submitField() {
			this.$refs.newField.validate(valid => {
				if (valid) {
					let sendObj = { name: '', options: {}, type: '', required: false }
					this.loading = true
					if (this.newField.type === 'multiplechoice' || this.newField.type === 'checkbox') {
						if (this.options.length == 1 || (this.options.length == 2 && this.options[1].text == '')) {
							this.loading = false
							return this.$message.error('Requires at least two Choices')
						}
						sendObj.name = this.newField.name
						sendObj.type = this.newField.type
						sendObj.options = {
							default: this.newField.value,
							multipleChoices: this.options,
							multipleAnswers: this.multiple,
							required: this.newField.required
						}
						sendObj.id = this.newField.id
						sendObj.options.multipleChoices = sendObj.options.multipleChoices.map(x => x = x.text)
						sendObj.user = this.user.id

						if (this.title == 'Add Custom Field') {
							sendObj.for = 'lot'
							this.$api.post(`/custom-fields/:instance`, sendObj).then(({ data }) => {
								this.$store.commit('SET_PROP', { where: ['customFields', data.id], what: data })
								this.loading = false
								this.cancelField()
							}).catch(err => {
								this.$message.error(this.$err(err))
								this.loading = false
								this.cancelField()
							})
						} else if (this.title == 'Edit Custom Field') {
							this.$api.put(`/custom-fields/:instance/${sendObj.id}`, sendObj).then(({ data }) => {
								this.$store.commit('UPDATE_CUSTOM_FIELD', data)
								this.loading = false
								this.cancelField()
							}).catch(err => {
								this.$message.error(this.$err(err))
								this.loading = false
								this.cancelField()
							})
						}

					} else if (this.newField.type == 'text' || this.newField.type == 'number' || this.newField.type == 'date') {
						sendObj.id = this.newField.id
						sendObj.name = this.newField.name
						sendObj.type = this.newField.type
						sendObj.options = {
							default: this.newField.value,
							multipleChoices: [],
							multipleAnswers: false,
							required: this.newField.required
						}
						sendObj.user = this.user.id

						if (this.title == 'Add Custom Field') {
							sendObj.for = 'lot'
							this.$api.post(`/custom-fields/:instance`, sendObj).then(({ data }) => {
								this.$store.commit('SET_PROP', { where: ['customFields', data.id], what: data })
								this.loading = false
								this.cancelField()
							}).catch(err => {
								this.$message.error(this.$err(err))
								this.loading = false
								this.cancelField()
							})
						} else if (this.title == 'Edit Custom Field') {
							this.$api.put(`/custom-fields/:instance/${sendObj.id}`, sendObj).then(({ data }) => {
								this.$store.commit('UPDATE_CUSTOM_FIELD', data)
								this.loading = false
								this.cancelField()
							}).catch(err => {
								this.$message.error(this.$err(err))
								this.loading = false
								this.cancelField()
							})
						}
					}
				}
			})
		},
		deleteField(item) {
			let self = this
			this.$confirm({
				title: "Delete Field",
				content: h => <div>Do you want to delete this Field?</div>,
				okText: 'Delete',
				okType: 'danger',
				cancelText: 'Cancel',
				centered: true,
				onOk() {
					self.$api.delete(`/custom-fields/:instance/${item.id}`).then(({ data }) => {
						self.$store.commit('DELETE_CUSTOM_FIELD', data)
						if (self.showFieldModal) {
							self.loading = false
							self.cancelField()
						}
					}).catch(err => {
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							self.$message.error(self.$err(err))
						}
					})
				}
			})
		},
	}
}
</script>

<style>
</style>
